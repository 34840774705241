import { graphql } from 'gatsby';
import React from 'react';
import Header from '../components/BasicHeader';
import Footer from '../components/Footer';
import Layout from '../components/Layout';

const MarkdownTemplate = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <Header />

      <section>
        <div className="container">
          <div className="section-heading text-center">
            <h2>{frontmatter.title}</h2>
            <hr />
            <br />
          </div>

          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </section>

      <Footer />
    </Layout>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;

export default MarkdownTemplate;
