import Button from '@material-ui/core/Button';
import { Link } from 'gatsby';
import React from 'react';
import config from '../../config';
import useCurrentUser from '../hooks/useCurrentUser';

import Banner from './Banner';

export default function Header() {
  const { currentUser, logout } = useCurrentUser();

  return (
    <>
      <nav
        className={'navbar navbar-expand-lg navbar-light fixed-top'}
        style={{ backgroundColor: 'white' }}
        id="mainNav"
      >
        <div className="container">
          <Link className="navbar-brand navbar-brand-basic" to="/">
            {config.siteTitle}
          </Link>

          {currentUser && <Button onClick={logout}>Logout</Button>}
        </div>
      </nav>
      <Banner
        style={{
          marginTop: 100,
          marginBottom: -50,
        }}
      />
    </>
  );
}
